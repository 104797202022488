import React from 'react';
import cN from 'classnames';
import { find } from 'lodash';
import s from './style.module';
import blubsch from '!svg-inline-loader!images/icons/blubsch_bold.svg';
import urgentCallSvg from '!svg-inline-loader!images/badges/urgent-call.svg';
import oneHourVolunteeringSvg from '!svg-inline-loader!images/badges/1-hour.svg';
import volunteeringDaySvg from '!svg-inline-loader!images/badges/volunteering-day.svg';
import specialDaySvg from '!svg-inline-loader!images/badges/special-day.svg';
import ResponsiveImage from '../ResponsiveImage';
import { CARD_IMAGE_SIZES, formatDate, formatTime, t } from '../helpers';
import locationIcon from '!svg-inline-loader!images/icons/location_marker_transparent_circle.svg';
import locationIndependentIcon from '!svg-inline-loader!images/icons/location_independent.svg';
import germanLanguageIcon from '!svg-inline-loader!images/icons/filter_german_language_skills.svg';
import frequencyIcon from '!svg-inline-loader!images/icons/filter_frequency.svg';
import dateIcon from '!svg-inline-loader!images/icons/filter_date.svg';

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.bindMethodsToThis();
    this.setProject();
    this.locationIndependentLabel = this.props.locationIndependentLabel;
    this.frequencyLabels = this.props.frequencyLabels;
    this.processTypeLabels = this.props.processTypeLabels;
    this.toProjectLabel = this.props.toProjectLabel;
    this.expanded = this.props.expanded;
    this.compact = this.props.compact;
    this.advanced = this.props.advanced;
    this.state = {expanded: false};
    this.projectEventHandler = this.props.projectEventHandler;
    if (this.projectEventHandler) {
      this.projectEventHandler.subscribe(this.otherProjectOpened);
    }
  }

  setProject() {
    this.id = this.props.id;
    this.title = this.props.title;
    this.description = this.props.description;
    this.organisationName = this.props.organisation_name;
    this.organisationLogo = this.props.organisation_logo;
    this.locationType = this.props.location_type;
    this.sublocalityAndCity = this.props.sublocality_and_city;
    this.frequency = this.props.frequency;
    this.germanLanguageSkills = this.props.german_language_skills;
    if (!this.props.onClick) {
      this.link = this.props.link;
    }
    this.date = this.props.date;
    this.processType = this.props.process_type;
    this.volunteeringDateInfo = this.props.volunteering_date_info;
    this.headerImage = find(this.props.images, image => {
      return image.url !== null;
    });
    if (!this.headerImage) {
      this.headerImage = {
        'w400': { 'url': '/images/default_project.svg' },
        'w700': { 'url': '/images/default_project.svg' },
        'w1300': { 'url': '/images/default_project.svg' },
        'w2600': { 'url': '/images/default_project.svg' }
      };
    }
    this.resolveBadgeImage(
      this.props.badge,
      this.props.urgent_call,
      this.props.volunteering_day,
      this.props.one_hour_volunteering,
      this.props.special_day
    );
  }

  bindMethodsToThis() {
    this.expand = this.expand.bind(this);
    this.processExpand = this.processExpand.bind(this);
    this.collapse = this.collapse.bind(this);
    this.clicked = this.clicked.bind(this);
    this.otherProjectOpened = this.otherProjectOpened.bind(this);
  }

  resolveBadgeImage(badge, urgentCall, volunteeringDay, oneHourVolunteering, specialDay) {
    if (badge) {
      this.badgeImage = <img className={cN(s.badge, this.expanded && s['badge--small'])} src={badge.image.w700.url} alt={badge.name} />;
    } else if (urgentCall) {
      this.badgeImage = <div className={cN(s.badge, this.expanded ? s['badge--small'] : null)} dangerouslySetInnerHTML={{ __html: urgentCallSvg }} />;
    } else if (volunteeringDay) {
      this.badgeImage = <div className={cN(s.badge, this.expanded ? s['badge--small'] : null)} dangerouslySetInnerHTML={{ __html: volunteeringDaySvg }} />;
    } else if (oneHourVolunteering) {
      this.badgeImage = <div className={cN(s.badge, this.expanded ? s['badge--small'] : null)} dangerouslySetInnerHTML={{ __html: oneHourVolunteeringSvg }} />;
    } else if (specialDay) {
      this.badgeImage = <div className={cN(s.badge, this.expanded ? s['badge--small'] : null)} dangerouslySetInnerHTML={{ __html: specialDaySvg }} />;
    } else {
      this.badgeImage = null;
    }
  }

  render() {
    this.setProject();
    return (
      <div className={cN(s.project, this.expanded ? s['project--expanded'] : null)}>
        <a href={this.link} className={s.link} onClick={this.clicked} onMouseEnter={this.expand} onMouseLeave={this.collapse}>
          {this.headerImage ? (
            <div className={s['header']}>
              <ResponsiveImage
                className={s['header-image']}
                sizes={CARD_IMAGE_SIZES}
                image={this.headerImage}
                alt=''
              />
            </div>
          ) : (
            <div className={s.header} />
          )}
          {this.badgeImage}
          {this.organisationLogo && this.organisationLogo['w400'] && this.organisationLogo['w400'].url && (
            <div className={cN(s['logo-container'], this.expanded || this.state.expanded ? s['logo-container--expanded'] : s['logo-container--collapsed'])}>
              <div className={s['logo-blubsch']} dangerouslySetInnerHTML={{ __html: blubsch }} />
              <div className={s['logo-wrapper']}>
                {/* The CSS class logo-container has a fixed with of 5rem, i.e. 80px. So we don't
                need the ResponsiveImage component here. */}
                <img
                  className={s.logo}
                  src={this.organisationLogo['w400'].url}
                  alt=''
                />
              </div>
            </div>
          )}
          <div className={cN(s.content, this.expanded || this.state.expanded ? s['content--expanded'] : s['content--collapsed'], this.expanded ? s['content--expanded-full-image'] : null)}>
            <h3 className={s.title}>{this.title}</h3>
            <h4 className={cN(`component-project__organisation`, s.organisation)}>{this.organisationName}</h4>
            {!this.compact && (
              <div>
                <div>
                  {this.date && this.date.start && (
                    <div>
                      {formatDate(new Date(this.date.start))} {formatTime(new Date(this.date.start))} – {formatTime(new Date(this.date.end))}
                      {'get_to_know' == this.processType ? ' (' + this.processTypeLabels['get_to_know'] + ')' : null}
                    </div>
                  )}
                </div>
                {'location_independent' == this.locationType ? (
                  <div className={cN(`project__attribute`, `component-project__location`, s.attribute, s['attribute--location'])}>
                    <span aria-hidden="true" className={'svg-icon'} dangerouslySetInnerHTML={{ __html: locationIndependentIcon }} />
                    {this.locationIndependentLabel}
                  </div>
                ) : (
                  <div className={cN(`project__attribute`, `component-project__location`, s.attribute, s['attribute--location'])}>
                    <span aria-hidden="true" className={'svg-icon'} dangerouslySetInnerHTML={{ __html: locationIcon }} />
                    {this.sublocalityAndCity}
                  </div>
                )}
                {(this.expanded || this.state.expanded) && (
                  <div className={cN(`project__attribute`, s.attribute)}>
                    <span aria-hidden="true" className={'svg-icon'} dangerouslySetInnerHTML={{ __html: frequencyIcon }} />
                    {this.frequencyLabels[this.frequency]}
                  </div>
                )}
                {(this.expanded || this.state.expanded) && (
                  <div className={cN(`project__attribute`, s.attribute)}>
                    <span aria-hidden="true" className={'svg-icon'} dangerouslySetInnerHTML={{ __html: germanLanguageIcon }} />
                    {t('project.german_language_skills.' + this.germanLanguageSkills)}
                  </div>
                )}
                {(this.expanded || this.state.expanded) && (
                  <div className={cN(`project__attribute`, s.attribute)}>
                    <span aria-hidden="true" className={'svg-icon'} dangerouslySetInnerHTML={{ __html: dateIcon }} />
                    {this.processTypeLabels[this.processType]}
                    {this.volunteeringDateInfo && (
                      <div className={s['volunteering-date-info']} dangerouslySetInnerHTML={{ __html: this.volunteeringDateInfo }} />
                    )}
                  </div>
                )}
                {this.advanced && (
                  <p className={s.description} dangerouslySetInnerHTML={{ __html: this.description}} />
                )}
              </div>
            )}
          </div>
        </a>
        {this.advanced && (
          <div>
            <a href={this.link} className={cN(`btn`, `btn-primary`)}>{this.toProjectLabel}</a>
          </div>
        )}
      </div>
    );
  }

  expand(event) {
    this.lastExpandedEventType = event.type;
    if (!this.processExpandTimeout) {
      this.processExpandTimeout = setTimeout(this.processExpand, 0);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  processExpand(event) {
    if (this.disableExpand) {
      return;
    }
    this.processExpandTimeout = null;
    this.setState({expanded: true, expandedEventType: this.lastExpandedEventType});
    if (this.projectEventHandler) {
      this.projectEventHandler.fire({ name: 'expand', instance: this });
    }
  }

  collapse(event) {
    this.setState({expanded: false});
    event.preventDefault();
    event.stopPropagation();
  }

  clicked(event) {
    this.disableExpand = true;
    if (this.props.onClick) {
      this.props.onClick.call(this, this.id);
    }
  }

  otherProjectOpened(event) {
    if (event.instance != this) {
      this.setState({expanded: false});
    }
  }
}

export default Project;
