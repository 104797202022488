import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="debounced-input"
export default class extends Controller {
  start() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.emitInputEnd(), 400);
  }

  emitInputEnd() {
    this.element.dispatchEvent(new Event('inputEnd', { bubbles: true }));
  }
}
