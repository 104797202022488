import React from 'react';
import ResponsiveImage from '../ResponsiveImage';
import s from './style.module';
import AnimatePath from '../AnimatePath';
import cN from 'classnames';
import urgentCallSvg from '!svg-inline-loader!images/badges/urgent-call.svg';
import oneHourVolunteeringSvg from '!svg-inline-loader!images/badges/1-hour.svg';
import volunteeringDaySvg from '!svg-inline-loader!images/badges/volunteering-day.svg';
import specialDaySvg from '!svg-inline-loader!images/badges/special-day.svg';
import blubsch from '!svg-inline-loader!images/icons/blubsch_bold.svg';
import { MOBILE_VIEW_UNTIL_WIDTH } from '../../common/vars';
import { isUserAgentIE } from '../../common/helper';


/**
 * I break down the following elements of the sizes list and explain their effects:
 * 
 * "(max-resolution: 2dppx) and (min-width: 1300px) 1300px" results in:
 *   - device-pixel-ratio 1 & large screen -> 1300px image
 *   - device-pixel-ratio 2 & large screen -> 2600px image (the browser multiplies the 1300px by 2)
 * "(min-width: 1300px) 866px" results in:
 *   - higher device-pixel-ratio & large screen: 2600px image
 * "(max-resolution: 1dppx) 700px" results in:
 *   - device-pixel-ratio 1 & smaller screen: 700px image
 * "(max-resolution: 2dppx) 350px" results in:
 *   - device-pixel-ratio 2 & smaller screen: 1300px image
 * "433px" results in:
 *   - device-pixel-ratio >= 3 & smaller screen: 1300px image (or 2600px for DPR > 3 but I don't
 *   think there are (many) devices with DPR > 3)
 */
const SIZES = '(max-resolution: 2dppx) and (min-width: 1300px) 1300px, (min-width: 1300px) 866px, (max-resolution: 1dppx) 700px, (max-resolution: 2dppx) 433px, 433px';


class ImageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.collapseMarginTopAt = this.props.collapseMarginTopAt || '1300px';
    this.decrement = this.decrement.bind(this);
    this.increment = this.increment.bind(this);
    
    this.images = this.props.images || [];
    if (!this.images.length && this.props.defaultImage) {
      this.images = [this.props.defaultImage];
    }

    this.state = { active: 0 };
    this.styleForElementsToHideInPreviewMode = {display: this.props.previewMode ? 'none' : ''};
    this.mobileView = false;
  }

  componentDidMount() {
    this.mqList = window.matchMedia('(max-width: ' + this.collapseMarginTopAt + ')')
    if (!isUserAgentIE()) {
      this.mqList.addEventListener("change",
        () => {  
          this.updateMarginTop(this.mqList.matches);
        });
    }
    this.updateMarginTop(this.mqList.matches);

    this.mobileView = window.matchMedia(`(max-width: ${MOBILE_VIEW_UNTIL_WIDTH}px)`).matches
  }

  updateMarginTop(collapseMarginTop) {
    this.setState({collapseMarginTop: collapseMarginTop}, () => {
      this.resolveBadgeImage(
        this.props.badge,
        this.props.urgentCall,
        this.props.volunteeringDay,
        this.props.oneHourVolunteering,
        this.props.specialDay
      );
    });
  }

  render() {
    if (!this.images.length) {
      return null;
    }

    const currentImage = this.images[this.state.active];
    const nextImage = this.images[(this.state.active + 1) % this.images.length];

    return (
      <div className={cN('section__hero', { [s.collapseMarginTop]: this.state.collapseMarginTop })} style={{height: this.props.height || '', minHeight: this.mobileView ? '210px' : null}}>
        <div className={cN('section__hero--background', s.container)} style={{borderTopRightRadius: this.props.borderTopRightRadius, borderBottomRightRadius: this.props.borderBottomRightRadius}}>
          <ResponsiveImage
            className={s.image}
            sizes={SIZES}
            image={currentImage}
            alt=''
          />
          {this.images.length > 1 && (
            <div>
              {/* We load this image even if most users won't probably never see it. The browser's
              lazy loading doesn't help here because the image is in the viewport.
              TODO: Use at least fetchpriority; or, even better, load the image asynchronously when
              the user clicks on an arrow. */}
              <ResponsiveImage
                className={cN(s.image, s.image__next)}
                sizes={SIZES}
                image={nextImage}
                alt=''
              />
              {this.state.active !== 0 && (
                <button className={s.left} onClick={this.decrement} id="image-header-decrement">
                  <AnimatePath duration={1} ease="ease" pathName="arrow" className={cN(`compenent-image-header__arrow`, s.arrow)} />
                </button>
              )}
              {this.state.active !== this.images.length - 1 && (
                <button className={s.right} onClick={this.increment} id="image-header-increment">
                  <AnimatePath duration={1} ease="ease" pathName="arrow" className={cN(`compenent-image-header__arrow`, s.arrow)} />
                </button>
              )}
            </div>
          )}
        </div>
        {this.state.badgeImage}
        {this.props.organisationLogo && this.props.organisationLogo['w400'] && this.props.organisationLogo['w400'].url && (
          <div className={cN(s['logo-container'], s['logo-container--expanded'])} style={this.styleForElementsToHideInPreviewMode} id="image-header-orga-logo">
            <div className={s['logo-blubsch']} dangerouslySetInnerHTML={{ __html: blubsch }} />
            <div className={s['logo-wrapper']}>
              {/* The CSS class logo-container has a fixed with of 100px. So we don't need
              the ResponsiveImage component here; in fact, the 400px wide logo is too
              large.
              TODO: Change NPO logo uploader so we get a smaller version and possibly an
              SVG. */}
              <img
                className={s.logo}
                src={this.props.organisationLogo['w400'].url}
                alt=''
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  increment() {
      this.setState({ active: (this.state.active + 1) % this.images.length });
  }
  decrement() {
      this.setState({ active: (this.state.active + this.images.length - 1) % this.images.length });
  }
  resolveBadgeImage(badge, urgentCall, volunteeringDay, oneHourVolunteering, specialDay) {
    let badgePositionWithCollapsedMarginTop = {[s['badge--collapsed-margin-top']]: this.state.collapseMarginTop};
    if (badge) {
      this.setState({badgeImage: <img id="image-header-badge" className={cN(s.badge, badgePositionWithCollapsedMarginTop)} src={badge.image.w700.url} style={this.styleForElementsToHideInPreviewMode} alt={badge.name} />});
    } else if (urgentCall) {
      this.setState({badgeImage: <div id="image-header-badge" className={cN(s.badge, badgePositionWithCollapsedMarginTop)} style={this.styleForElementsToHideInPreviewMode} dangerouslySetInnerHTML={{ __html: urgentCallSvg }} />});
    } else if (volunteeringDay) {
      this.setState({badgeImage: <div id="image-header-badge" className={cN(s.badge, badgePositionWithCollapsedMarginTop)} style={this.styleForElementsToHideInPreviewMode} dangerouslySetInnerHTML={{ __html: volunteeringDaySvg }} />});
    } else if (oneHourVolunteering) {
      this.setState({badgeImage: <div id="image-header-badge" className={cN(s.badge, badgePositionWithCollapsedMarginTop)} style={this.styleForElementsToHideInPreviewMode} dangerouslySetInnerHTML={{ __html: oneHourVolunteeringSvg }} />});
    } else if (specialDay) {
      this.setState({badgeImage: <div id="image-header-badge" className={cN(s.badge, badgePositionWithCollapsedMarginTop)} style={this.styleForElementsToHideInPreviewMode} dangerouslySetInnerHTML={{ __html: specialDaySvg }} />});
    } else {
      this.setState({badgeImage: null});
    }
  }
}

export default ImageHeader;
